<script>
    export let title = ''
</script>
<div class="headerBox">
    {title}
</div>

<style>
    .headerBox{
        display: flex;
        box-shadow: 2px 4px 12px rgba(0,0,0,.08);
        border-radius: 18px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        height: 150px;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 20px;
        color: white;
        background: var(--color-gradient);
    }
</style>