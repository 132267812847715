<svelte:head>
	<title>Survey Pillars of Possibility</title>
	<link rel="preconnect" href="https://fonts.googleapis.com"> 
	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin> 
	<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
<!-- 3h -->
</svelte:head>

<script>
	import Header from './Header.svelte'

	import Question from './Question.svelte'

	import Save from './Save.svelte'

	import Success from './Success.svelte'

	import Loading from './Loading.svelte'

	import Error from './Error.svelte'

	import NoSurvey from './NoSurvey.svelte'

	import {resultSave, fetchSurvey} from './survey.js'
	let state = 'loading'

	let questions = []
	let values = []
	let meta = {title:"Survey",color_1:"rgb(130,0,255)",color_2:"rgb(223,0,254)"}

	async function submit(){
		let required = true
		for(var i=0;i<values.length;i++){
			if(values[i]==null || values[i].length==0){
				required=false
			}
		}
		console.log(values)
		console.log(required)
		if(required==true){
			state="loading"
			console.log(state)
			state = await resultSave('feedbackPoP', values)
			console.log(state)
		}
	}

	$: fetchQuestions()

	async function fetchQuestions () {
		var survey = await fetchSurvey()
		if(survey.status==""){
			state = ""
			questions = survey.data
			meta = survey.meta
		} else{
			state = survey.status
		}
		document.documentElement.style.setProperty('--color-1',meta.color_1)
		document.documentElement.style.setProperty('--color-2',meta.color_2)
	}
</script>

<main>
	<Header title={state=='nosurvey' ? 'OOPS':meta.title}/>
	{#if state=='submitted'}
		<Success/>
	{:else if state=='loading'}
		<Loading/>
	{:else if state=='error'}
		<Error/>
	{:else if state=='nosurvey'}
		<NoSurvey/>
	{:else}
	<div class="questionContainer">
		{#each questions as question, index}
		<Question {question} bind:value={values[index]}/>
		{/each}
	</div>
	<Save on:message={()=> submit()}/>
	{/if}
</main>

<style>
	main {
		/* background: linear-gradient(170deg, rgb(115,67,227) 0%, rgb(69, 28, 178) 50%); */
		max-width: 720px;
		margin: auto;
		padding: 20px;
	}

	.questionContainer{
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
</style>