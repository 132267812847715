<div class="errorBox">
    <img src="/img/circle-xmark-solid.svg" alt="Red circle with cross symbolising an error">
    <div class="text">Error</div>
</div>

<style>
    .errorBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 40px;
    }

    .errorBox img{
        width: 40%;
    }

    .errorBox .text{
        font-size: large;
        font-weight: 400;
    }
</style>