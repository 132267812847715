import { initializeApp } from 'firebase/app';
import { getDatabase, push, ref, get, child } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDCGV856yOOPfq7qCFSwlC2oNuLjsHI3jY",
    authDomain: "pillarsofpossibility.co.uk",
    databaseURL: "https://pillarsofpossibility-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pillarsofpossibility",
    storageBucket: "pillarsofpossibility.appspot.com",
    messagingSenderId: "207385966018",
    appId: "1:207385966018:web:45ff7318e51804876e125b",
    measurementId: "G-6FV2PVYD9Q"
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);

var search = location.search.substring(1);

var surveyMetaData = (search!="" ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'): "")

console.log(surveyMetaData)
export async function resultSave(data){
    var responseData = {
        response: data,
        meta: surveyMetaData
    }
    console.log(responseData)
    return push(ref(database, 'snowdon/'+surveyMetaData.survey+'/responses/'), responseData).then(()=>{
        return 'submitted'
    }).catch(()=>{
        return 'error'
    })
}

export async function fetchSurvey(){
    var surveyID = surveyMetaData.survey
    return get(child(ref(database), 'snowdon/'+surveyID+'/surveyData/')).then((snapshot)=>{
        if(snapshot.exists()){
            var questionData = []
            var meta
            snapshot.forEach((item)=>{
                var data = item.val()
                if(item.key!="meta"){
                    data.id = item.key
                    questionData.push(data)
                } else {
                    meta = data
                }
            })
            return {
                status: '',
                data: questionData,
                meta
            }
        } else{
            return {
                status: 'nosurvey'
            }
        }
    }).catch((error)=>{
        console.error(error)
        return {
            status: 'error',
        }
    })
}