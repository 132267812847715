<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function submit() {
        dispatch('message', {
            text: 'Submit'
        });
    }
</script>

<div class="saveBox">
    <div class="saveButton" on:click="{()=> submit()}">
        Submit
    </div>
</div>

<style>
    .saveBox{
        display: flex;
        justify-content: center;
        padding: 20px;
    }
    .saveButton{
        width: max-content;
        padding: 20px 40px;
        background: var(--color-gradient);
        font-size: larger;
        font-weight: 600;
        border-radius: 18px;
    }
</style>