<div class="nosurveyBox">
    <div class="message">We couldn't find the survey you are looking for<br><br>Try contacting the person who gave you this link</div>
</div>

<style>
    .nosurveyBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 40px;
    }

    .nosurveyBox .message{
        font-size: larger;
        font-weight: bold;
        text-align: center;
    }
</style>