<div class="loadingBox">
    <div class="loadingContainer">
        <div class="loader"></div>
        <div class="loader"></div>
    </div>
</div>

<style>
    .loadingBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 40px;
    }

    .loadingContainer{
        position: relative;
        width: 100px;
        height: 100px;
        grid-template-columns: repeat(2, minmax(0,1fr));
        /* outline: 1px solid green; */
        gap: 20px;
        /* animation: rotate 2s cubic-bezier(.74,-0.15,.46,1.1) infinite; */
    }

    .loadingSquare{
        position: absolute;
        background-color: var(--color-1);
        width: 40px;
        height: 40px;
        border-radius: 15px;
        transform: translate(0,0);
        transform-origin: center;
        animation: rotate;        
    }

    .loadingSquare:nth-child(1){
        background-color: red;
        animation: square1 5s infinite linear;
    }

    .loadingSquare:nth-child(2){
        background-color: green;

        animation: square2 5s infinite linear;
    }

    .loadingSquare:nth-child(3){
        background-color: blue;

        animation: square3 5s infinite linear;
    }
    .loadingSquare:nth-child(4){
        background-color: yellow;

        animation: square4 5s infinite linear;
    }
    .loader {
        position: absolute;
        top: 0;
        left: 0;
        border: 5px solid transparent;
        border-radius: 50%;
        border-top: 2px solid #c8081588;
        width: 100%;
        height: 100%;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
    .loader:nth-child(2) {
        width: 110%;
        height: 110%;
        margin: -5%;
        -webkit-animation: spin 1s 0.2s linear infinite;
        animation: spin 1s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>