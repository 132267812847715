<script>
    export let question
    export let value = null

    if(question.type=="checkboxes"){
        value = []
    }

    function togglevalue(indexvalue){
        if(value.includes(indexvalue)){
            let index = value.indexOf(indexvalue)
            value.splice(index, 1)
        } else {
            value.push(indexvalue)
        }
        value = value
    }
</script>

<div class="questionBox">
    <div class="question">{question.question}</div>
    {#if question.type=="linear-scale"}

    <div class="linearScale">
        {#each Array(question.max_value - question.min_value + 1) as _, i}
            <input type="radio" name={question.id} id="">
        {/each}
    </div>

    {:else if question.type=="checkboxes"}

    <div class="checkboxes">
        {#each question.options as option, index}
            <div class:active="{value.includes(index)}" class="button" on:click='{() => togglevalue(index)}'>
                {option}
            </div>
        {/each}
    </div>

    {:else if question.type=="happiness"}

    <div class="happiness">
        <div class:active="{value == 1}" class="button" on:click='{() => value = 1}'>
            <img src="/img/angry.png" alt="Angry face">
        </div>
        <div class:active="{value == 2}" class="button" on:click="{() => value = 2}">
            <img src="/img/sad.png" alt="Sad face">
        </div>
        <div class:active="{value == 3}" class="button" on:click="{() => value = 3}">
            <img src="/img/neutral.png" alt="Neutral face">
        </div>
        <div class:active="{value == 4}" class="button" on:click="{() => value = 4}">
            <img src="/img/smile.png" alt="Happy face">
        </div>
        <div class:active="{value == 5}" class="button" on:click="{() => value = 5}">
            <img src="/img/smiling.png" alt="Very happy face">
        </div>
    </div>

    {/if}
</div>

<style>
    .button{
        cursor: pointer;
    }

    
    .questionBox{
        /* box-shadow: 2px 4px 12px rgba(0,0,0,.1); */
        border-radius: 18px;
        padding: 20px;
    }

    .question{
        font-size: larger;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
    }

    .linearScale{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .checkboxes{
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));
        gap: 20px;
    }

    .checkboxes .button{
        text-align: center;
        background-color: #272727;
        /* background: var(--color-gradient); */
        padding: 30px 10px;
        border-radius: 18px;
        font-weight: 600;
        opacity: 0.6;
        transition: opacity 0.25s ease-in-out;
    }

    .checkboxes .button:hover{
        opacity: 1;
    }

    .checkboxes .button.active{
        opacity: 1;
        background: var(--color-gradient);

    }

    .happiness{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .happiness .button{
        padding: 10px;
        opacity: 0.5;
        transition: opacity 0.25s ease-in-out;

    }

    .happiness .button:hover{
        opacity: 1;
    }

    .happiness img{
        width: 100%;
    }

    .button.active{
        opacity: 1;
    }
</style>