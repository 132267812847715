<div class="successBox">
    <img src="/img/circle-check-solid.svg" alt="Green circle with a tick symbolising success">
    <div class="text">Success</div>
</div>

<style>
    .successBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 40px;
    }

    .successBox img{
        width: 40%;
    }

    .successBox .text{
        font-size: large;
        font-weight: 400;
    }
</style>